import { createAction } from "redux-actions";

export const BANK_TRANSACTION = "BANK_TRANSACTION";
export const bankTransaction = createAction(BANK_TRANSACTION);

export const TRANSACTION_RECEIPT = "TRANSACTION_RECEIPT";
export const transactionReceipt = createAction(TRANSACTION_RECEIPT);

export const TRANSACTION_STATUS = "TRANSACTION_STATUS";
export const transactionStatus = createAction(TRANSACTION_STATUS);
