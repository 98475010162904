import React, { useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Navigate, useSearchParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { errorMessage } from "../../utilities/notification";
import {
  tranxDetail,
  fetchTransactionsGraphData,
} from "../../stores/actions/bfm.actions.type";
import TranxTable from "../../components/tables/TranxTable";
import Loader from "../../components/Loader";
import SpendByCategoriesDoughnutChart from "../../components/UI/SpendByCategoryPieChart";
import MostSpendSection from "../../components/UI/MostSpendSection";
import { map, sortBy, filter } from "underscore";
const { PUBLIC_URL } = process.env;

const FundManagementTranx = (props) => {
  const [tranxs, setTranx] = useState([]);
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("accountId");
  const entityId = searchParams.get("entityId");
  const [mostSpendlist, setMostSpendList] = useState([]);

  useEffect(() => {
    const list = filter(
      sortBy(
        map(props.graphData.groupedTransactions, function (val, key) {
          return { key, ...val };
        }),
        function (el) {
          return el.amount;
        }
      ),
      (el) => {
        return el.type === "dr";
      }
    )
      .reverse()
      .slice(0, 8);
    setMostSpendList(list);
    return () => setMostSpendList([]);
  }, [props.graphData]);
  useEffect(() => {
    try {
      if (props.graphDataReqUid !== `${accountId}-${entityId}`) {
        const callback = (data) => {
          if (data.success) {
            setTranx(data?.data?.data ? data?.data?.data : []);
          }
        };
        props.fetchTransactionsGraphData({
          accountId,
          entityId,
          callback: () => {
            props.tranxDetail({
              accountId,
              entityId,
              callback,
            });
          },
        });
      }
    } catch (error) {
      errorMessage(error.reason);
    }
  }, [accountId, entityId]);

  return (
    <AuthContext.Consumer>
      {(auth) => {
        if (!auth) {
          return <Navigate to="/login" />;
        }
        return (
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-transactions"
              role="tabpanel"
              aria-labelledby="pills-transactions-tab"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="transactionCategryCol">
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="mb-0 fw-bold text-primary">Categories</h5>
                      <div className="transactionCategryColTpButton">
                        <button
                          type="button"
                          className="btn btn-light text-uppercase"
                        >
                          Yearly
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary text-uppercase"
                        >
                          MONTHLY
                        </button>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between transCatIncomeCol">
                      <SpendByCategoriesDoughnutChart
                        chartData={mostSpendlist}
                      />

                      <div className="incomeReviewCol">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Spent{" "}
                          <span className="fw-bold text-primary">
                            {props?.graphData?.totals?.outflow?.amount?.toFixed(
                              2
                            ) || 0} {props?.graphData?.totals?.outflow?.currency}
                          </span>
                        </p>
                        <p className="d-flex align-items-center justify-content-between">
                          Total Income{" "}
                          <span className="fw-bold text-primary">
                            {props?.graphData?.totals?.inflow?.amount?.toFixed(
                              2
                            ) || 0} {props?.graphData?.totals?.inflow?.currency}
                          </span>
                        </p>
                        <p className="d-flex align-items-center justify-content-between">
                          Disposable Income{" "}
                          <span className="fw-bold text-primary">
                            {(
                              (props?.graphData?.totals?.inflow?.amount || 0) -
                              (props?.graphData?.totals?.outflow?.amount || 0)
                            ).toFixed(2)} {props?.graphData?.totals?.outflow?.currency}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="transactionMostSpentCol">
                    <h5 className="mb-3 fw-bold text-primary">Most Spent</h5>
                    <MostSpendSection list={mostSpendlist} />
                  </div>
                </div>
              </div>
              {/* table */}
              <div className="transactionTable mt-4">
                <h6 className="text-primary mb-2">Transactions</h6>
                {props?.visible ? <Loader /> : <TranxTable list={tranxs} />}
              </div>
            </div>
          </div>
        );
      }}
    </AuthContext.Consumer>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
  graphData: state?.bfm?.transactionsGraphData || {},
  graphDataReqUid: state?.bfm?.transactionsGraphDataReqUid || "",
});

export default connect(mapStateToProps, {
  tranxDetail,
  fetchTransactionsGraphData,
})(FundManagementTranx);
