import { createSlice } from "@reduxjs/toolkit";
const initialState = { notificationsList: {} };

export const notificationsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state = initialState, { payload }) => {
      return {
        ...state,
        notificationsList: payload,
      };
    },
    setSocketNotifications: (state = initialState, { payload }) => {
      let data = { pagination_data:{...state.notificationsList.pagination_data}, data:[ payload, ...state.notificationsList.data]};
      return {
        ...state,
        notificationsList: data,
      };
    },
  },
  extraReducers: {},
});
