import io from "socket.io-client";
const { REACT_APP_SOCKET_URL } = process.env;
console.log(REACT_APP_SOCKET_URL)

export const socket =(token)=> {
  if(token) {
    return io.connect(REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
      auth: {
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    })
  }
};
